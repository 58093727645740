/**
 * Proxy of iFrame parent window
 * Handles messaging between widget iframe to hosting window messaging
 * persisting parent window properties, pageXOffset, pageYOffset...
 */
import { EXPOSED_WINDOW_PROPERTIES, EM_EVENT, EXPOSED_WINDOW_EVENTS } from '../common/Constants'
import { getPropertyByPath, foreach, eventFilter } from '../common/Helper'
import ssfGuest from '@elliemae/em-ssf-guest'
import { Logger } from '../common/Logger'
let parentWindowProperties = {}
let widgetIFrameProperties = {}
let widgetMetadata = {}
let widgetStoreData = {}
const registeredCallbacks = (() => {
  // initialization with default value
  const callbacks = {}
  for (const event in EXPOSED_WINDOW_EVENTS) {
    callbacks[EXPOSED_WINDOW_EVENTS[event]] = []
  }
  return callbacks
})()

let isLauncherConnected = false
let isSSFHostConnected = false
// ssfGuest.setLogLevel(ssfGuest.logLevels.Verbose)

export const resolveParent = () => {
  try {
    // test whether parent window is accessible or not
      const parentLocation = top.location.href //eslint-disable-line
    return window.parent
  } catch (e) {
    return null
  }
}

const setupWindowEventListeners = function () {
  window.addEventListener('load', () => {
    // notify launcher that widget can start listening to messages
    this.notify({
      name: EM_EVENT.widgetConnected
    })
  })
  // listen to frame message coming from widget launcher
  window.addEventListener('message', (event) => {
    // console.log('==== Widget received message from Launcher ====', event.data); //eslint-disable-line
    // filter message
    if (!eventFilter(event)) {
      return
    }
    const eventObject = JSON.parse(event.data)
    switch (eventObject.name) {
      case EM_EVENT.launcher2Widget:
        isLauncherConnected = true
        widgetMetadata = eventObject.message
        break
      case EM_EVENT.sendStoredDataToWidget:
        widgetStoreData = eventObject.message
        break
      case EM_EVENT.widgetIFramePropertiesUpdate:
        widgetIFrameProperties = eventObject.message
        break
      default:
    }
  }, false)
}

const setReadOnlyProperty = (propertyName) => {
  Object.defineProperty(ParentWindowProxy, propertyName, {
    get: function () {
      let parentWindow = resolveParent()
      if (parentWindow !== null) {
        return getPropertyByPath(parentWindow, propertyName)
      } else {
        return parentWindowProperties[propertyName]
      }
    }
  })
}

let setMutationObserver = () => {
  if (!window.MutationObserver) {
    return
  }

  let body = document.body
  let config = { attributes: true, childList: true, subtree: true }

  let callback = (mutationsList) => {
    for (let mutation of mutationsList) {
      if (mutation.type === 'childList') {
        // notify launcher that resize event occurred in widget
        let newHeight = document.getElementById('root').clientHeight
        ParentWindowProxy.setIFrameHeight(newHeight)
      }
    }
  }
  let observer = new window.MutationObserver(callback)
  observer.observe(body, config)
}

const addEventListener = (event, callback) => {
  const index = registeredCallbacks[event].findIndex(cb => cb === callback)
  if (index === -1) {
    registeredCallbacks[event].push(callback)
  }
}

const removeEventListener = (event, callback) => {
  if (typeof callback === 'function') {
    registeredCallbacks[event] = registeredCallbacks[event].filter(cb => cb !== callback)
  }
}

const executeEventListeners = (eventName) => {
  registeredCallbacks[eventName].forEach(cb => cb())
}

/**
   * Object represeting parent window. This object will be accessible from Widget.parent property
   * @namespace ParentWindowProxy
   * @property {number} innerHeight
   * @property {number} innerWidth
   * @property {number} pageXOffset
   * @property {number} pageYOffset
   * @property {object} location
   * @property {string} location.search
   * @property {string} location.hash
   * @property {string} location.href
   * @property {object} performance
   * @property {string} performance.navigation.type
   * @property {number} performance.timing.fetchStart
   *
   * @example
   * 'Widget.parent.innerHeight',
   * 'Widget.parent.innerWidth',
   * 'Widget.parent.pageXOffset',
   * 'Widget.parent.pageYOffset',
   * 'Widget.parent.location',
   * 'Widget.parent.performance'
   */
const ParentWindowProxy = {
  get isParentWindowConnected () {
    return isLauncherConnected && isSSFHostConnected
  },
  get iFrameProperties () {
    return widgetIFrameProperties
  },
  get metadata () {
    return Object.assign({}, widgetMetadata, { isWidget: ParentWindowProxy.isCrossOriginParentWindow() })
  },

  get storedata () {
    return Object.assign({}, widgetStoreData)
  },
  /**
     * This will initialize the parent window object
     * @memberof ParentWindowProxy
     * @param {function} callback when message received
     */
  init: function (wid) {
    // Retrieve the application object
    this.wid = wid
    // setup window event Listeners
    setupWindowEventListeners.call(this)
    setMutationObserver.call(this)

    this._sameOriginParent = resolveParent()

    if (!this._sameOriginParent) {
      // create SSF guest
      ssfGuest.guest.create()

      // need self = this to prevent webpack to do wrong this reference for IE11
      let self = this
      // retrieve Guest object
      ssfGuest.getObject('HostWindow').then((hostWindow) => {
        self._hostWindow = hostWindow
        isSSFHostConnected = true
        // setup event subscriber
        ssfGuest.subscribe('HostWindow', 'windowPropertyChanged', (obj, eventData) => {
          parentWindowProperties = eventData
        })

        // setup event subscriber
        ssfGuest.subscribe('HostWindow', 'scrolled', (obj, eventData) => {
          executeEventListeners(EXPOSED_WINDOW_EVENTS.SCROLL)
        })

        // setup event subscriber
        ssfGuest.subscribe('HostWindow', 'navigated', (obj, eventData) => {
          executeEventListeners(EXPOSED_WINDOW_EVENTS.POP_STATE)
        })

        ssfGuest.subscribe('HostWindow', 'bodyResized', () => {
          executeEventListeners(EXPOSED_WINDOW_EVENTS.ON_BODY_RESIZE)
        })

        ssfGuest.subscribe('HostWindow', 'windowResized', () => {
          executeEventListeners(EXPOSED_WINDOW_EVENTS.ON_WINDOW_RESIZE)
        })

        ssfGuest.subscribe('HostWindow', 'visualViewportResized', () => {
          executeEventListeners(EXPOSED_WINDOW_EVENTS.ON_VISUAL_VIEWPORT_RESIZE)
        })
      })
    }

    return this
  },

  /**
     * This function will handle post message to parent window
     * @memberof ParentWindowProxy
     * @param message
     */
  notify: function (message) {
    const postMessage = JSON.stringify(Object.assign({}, message, { wid: this.wid }))
    Logger.debug('Widget post message to launcher: Event Data: ' + postMessage)
    window.parent.postMessage(postMessage, '*')
  },
  /**
     * This function can scroll parent window to a coordinate
     * @memberof ParentWindowProxy
     * @param {integer} x x-axis scroll position
     * @param {integer} y y-axis scroll position
     */
  scrollTo: function (x, y) {
    const parentWindow = this._sameOriginParent
    if (parentWindow !== null) {
      parentWindow.scrollTo(x, y)
    } else {
      if (this._hostWindow) {
        // sending to launcher to handle it
        this._hostWindow.scrollTo(x, y)
      }
    }
  },
  /**
     * This function replace history state of the parent window
     * @memberof ParentWindowProxy
     * @param {object} The state object is a JavaScript object which is associated with the new history entry
     * @param {string} title a short title for the state to which you're moving
     * @param {string} url The new history entry's URL
     */
  replaceState: function (stateObj, title, url) {
    const parentWindow = this._sameOriginParent
    if (parentWindow !== null) {
      parentWindow.history.replaceState(stateObj, title, url)
    } else {
      if (this._hostWindow) {
        this._hostWindow.replaceState(stateObj, title, url)
      }
    }
  },
  /**
     * This function add new history state to the parent window
     * @memberof ParentWindowProxy
     * @param {object} The state object is a JavaScript object which is associated with the new history entry
     * @param {string} title a short title for the state to which you're moving
     * @param {string} url The new history entry's URL
     */
  pushState: function (stateObj, title, url) {
    const parentWindow = this._sameOriginParent
    if (parentWindow !== null) {
      parentWindow.history.pushState(stateObj, title, url)
    } else {
      if (this._hostWindow) {
        this._hostWindow.pushState(stateObj, title, url)
      }
    }
  },
  /**
     * function returns whether parent frame is cross origin or not
     * @memberof ParentWindowProxy
     * @returns {boolean} isWidget
     */
  isCrossOriginParentWindow: function () {
    return !resolveParent()
  },

  disableBrowserBack: function () {
    const parentWindow = this._sameOriginParent
    if (parentWindow) {
      parentWindow.history.pushState({ disableBrowserBack: true }, parentWindow.document.title)
      parentWindow.onpopstate = (e) => {
        if (e.state.disableBrowserBack === true) {
          parentWindow.history.pushState({ disableBrowserBack: true }, parentWindow.document.title)
          parentWindow.scrollTo(0, 0)
        }
      }
    } else {
      if (this._hostWindow) {
        this._hostWindow.disableBrowserBack()
      }
    }
  },
  /**
     * This function will redirect the parent window to new location
     * @memberof ParentWindowProxy
     */
  redirect: function (url) {
    const parentWindow = this._sameOriginParent
    if (parentWindow !== null) {
      parentWindow.location.href = url
    } else {
      if (this._hostWindow) {
        this._hostWindow.redirect(url)
      }
    }
  },

  /**
     * This function will write new content to parent document
     * @memberof ParentWindowProxy
     */
  documentWrite: function (html) {
    const parentWindow = this._sameOriginParent
    if (parentWindow !== null) {
      parentWindow.document.write(html)
    } else {
      if (this._hostWindow) {
        // sending to launcher to handle it
        this._hostWindow.documentWrite(html)
      }
    }
  },

  setIFrameHeight: function (newHeight) {
    if (this._hostWindow) {
      this._hostWindow.setIFrameHeight(this.wid, newHeight)
    }
  },

  persistWidgetUrlState: function (state, forceResume = false) {
    const parentWindow = this._sameOriginParent
    if (!parentWindow && this._hostWindow) {
      this._hostWindow.persistWidgetUrlState(this.wid, state, forceResume)
    }
  },

  onPopState: function (callback) {
    if (typeof callback === 'function') {
      const parentWindow = this._sameOriginParent
      if (parentWindow !== null) {
        window.parent.addEventListener('popstate', callback)
      } else {
        addEventListener(EXPOSED_WINDOW_EVENTS.POP_STATE, callback)
      }
    }
  },

  onScroll: function (callback) {
    if (typeof callback === 'function') {
      const parentWindow = this._sameOriginParent
      if (parentWindow !== null) {
        window.parent.addEventListener('scroll', callback)
      } else {
        addEventListener(EXPOSED_WINDOW_EVENTS.SCROLL, callback)
      }
    }
  },

  removeOnScroll: function (callback) {
    if (typeof callback === 'function') {
      const parentWindow = this._sameOriginParent
      if (parentWindow !== null) {
        window.parent.removeEventListener('scroll', callback)
      } else {
        removeEventListener(EXPOSED_WINDOW_EVENTS.SCROLL, callback)
      }
    }
  },

  openNewWindow: function (URL, name, specs, replace) {
    this._hostWindow.openNewWindow(URL, name, specs, replace)
  },

  historyGo: function (historyNumber = -1) {
    this._hostWindow.historyGo(historyNumber)
  },

  setStateItem: function (key, state) {
    return this._hostWindow.setStateItem(key, state)
  },

  removeStateItem: function (key) {
    this._hostWindow.removeStateItem(key)
  },

  clearState: function () {
    this._hostWindow.clearState()
  },

  printSkyDriveDocument: function (blob) {
    return this._hostWindow.printSkyDriveDocument(blob)
  },

  bodyResizeObserver: function () {
    return {
      subscribe: (callback) => {
        if (typeof ResizeObserver !== 'undefined' && typeof callback === 'function') {
          const isCallBackArrayEmpty = registeredCallbacks[EXPOSED_WINDOW_EVENTS.ON_BODY_RESIZE].length === 0

          if (isCallBackArrayEmpty) {
            this._hostWindow.subscribeBodyResizeObserver()
          }
          addEventListener(EXPOSED_WINDOW_EVENTS.ON_BODY_RESIZE, callback)
        }
      },
      unsubscribe: (callback) => {
        if (typeof ResizeObserver !== 'undefined' && typeof callback === 'function') {
          removeEventListener(EXPOSED_WINDOW_EVENTS.ON_BODY_RESIZE, callback)
          const isCallBackArrayEmpty = registeredCallbacks[EXPOSED_WINDOW_EVENTS.ON_BODY_RESIZE].length === 0

          if (isCallBackArrayEmpty) {
            this._hostWindow.unSubscribeBodyResizeObserver()
            registeredCallbacks[EXPOSED_WINDOW_EVENTS.ON_BODY_RESIZE] = []
          }
        }
      }
    }
  },

  onWindowResize: function (callback) {
    if (typeof callback === 'function') {
      addEventListener(EXPOSED_WINDOW_EVENTS.ON_WINDOW_RESIZE, callback)
    }
  },

  onVisualViewportResize: function (callback) {
    if (typeof callback === 'function') {
      addEventListener(EXPOSED_WINDOW_EVENTS.ON_VISUAL_VIEWPORT_RESIZE, callback)
    }
  },

  removeOnWindowResize: function (callback) {
    if (typeof callback === 'function') {
      removeEventListener(EXPOSED_WINDOW_EVENTS.ON_WINDOW_RESIZE, callback)
    }
  },

  removeOnVisualViewportResize: function (callback) {
    if (typeof callback === 'function') {
      removeEventListener(EXPOSED_WINDOW_EVENTS.ON_VISUAL_VIEWPORT_RESIZE, callback)
    }
  },

}

foreach(EXPOSED_WINDOW_PROPERTIES, function (propertyName) {
  setReadOnlyProperty(propertyName)
})

export default ParentWindowProxy
