import '../common/polyfills'
import Launcher from './Launcher'
import { launchDeclarativeWidget } from './DeclarativeWidget'
import { Logger, LEVEL } from '../common/Logger'
/**
 * EM - EllieMae namespace
 * provides method to create Widget on lender site
 * @namespace EM
 */
window.EM = (function (EllieMae) {
  EllieMae = {
    _launcherInstance: null,
    getLauncherInstance: function () {
      return this._launcherInstance
    },
    /**
     * initialize the widget launcher for a siteId
     * launch all registered widgets
     * @function init
     * @memberof EM
     * @param {object} options
     * @param {string} options.siteId EllieMae lender site id
     * @returns {Launcher}
     * @example
     * EM.init({
     *  siteId: '1231545231',
     *  logLevel: 1,
     * });
    */
    init (options) {
      Logger.setLogLevel(options.logLevel || LEVEL.WARN)
      if (!this._launcherInstance) {
        this._launcherInstance = new Launcher(options)
        return this._launcherInstance
      }
      return this._launcherInstance
    },
    /**
     * This method create a widget instance in launcher and render the widget iframe
     * @function ui
     * @memberof EM
     * @param {object} options
     * @param {string} options.selector HTML dom selector
     * @param {string} options.name Widget name, if name is provided it open widget according to internal defined url
     * @param {object} options.config Widget configuration object
     * @param {function} callback When widget emit message, this call back will be invoked
     * @returns {Widget}
     * @example
     * EM.ui({
     *  selector: '#test1',
     *  name: 'calculator-widget'
     *  config: {
     *    ...
     *  }
     * }, function(response){
     *      // handle widget response here
     *    });
     */
    ui (options, callback) {
      /**
       * initlaize widget with options and return widget instance
       */
      if (!this._launcherInstance) {
        return
      }
      return this._launcherInstance.initWidget(options, callback)
    }
  }

  // handle declarative widgets
  launchDeclarativeWidget(EllieMae)

  return EllieMae
}(window.EM || {}))
