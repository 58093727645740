import { objectToQueryString, queryStringToObject, getViewPortDimension,
  resolveObjectFromNamespace, resolvePropertiesFromObject, getPropertyByPath,
  filterObjectProperties, migrateMetadata } from '../common/Helper'
import { AUTH_WIDGET_INSTANCE_ID, WIDGET_STATE, WINDOW_RELOAD,
  LOWEST_WIDGET_MIN_HEIGHT, EXTENDS_PROPERTIES, DEFAULT_AUTH_TIMEOUT,
  EM_EVENT, META_PROPERTIES, APP_SESSION_PREFIX } from '../common/Constants'
import { Logger } from '../common/Logger'

const RESUME_STATE_KEY = '_FORCE_RESUME_STATE'
const INIT_STATE_KEY = '_INIT_STATE'
/**
 * This Widget Class represent the iframe widget
 * Bootstraping iframe creation
 * passing initial url parameters to iframe
 * Provides messaging api for launcher and widget
 */
class Widget {
  /**
   * Create a widget
   * @constructor
   * @param {object} options
   * @param {string} options.selector iframe container selector
   * @param {string} options.siteId lender site id
   * @param {string} options.wid Widget instance id, this id is generated by widget launcher
   * @param {string} options.style iframe style
   * @param {object} options.config iframe intial parametersm, this will be append to the iframe url parameters
   * @param {function} callback Widget callback, it is a message listener, invoke when widget emits a message
   */
  constructor (options = {}, callback) {
    this.selector = options.selector
    this.siteId = options.siteId
    this.wid = options.wid
    this.ariaLabel = options.ariaLabel
    this.baseUrl = options.baseUrl
    this.basePath = options.basePath
    this.style = options.style || {}
    this.callback = callback
    this.params = filterObjectProperties(options.config, META_PROPERTIES, false) // config becomes url params for widget
    this.state = WIDGET_STATE.INITIAL
    this.iFramePosition = {}
    // meta properties are communicated before widget is ready
    this.meta = migrateMetadata(filterObjectProperties(options.config, META_PROPERTIES, true))
    this.guest = {} // guest reference created by ssf host
    // Add extended functions
    this.extends = this._resolveWidgetExtendedProperties(options.extends)
  }
  /**
   * This set app data to session storage of Widget window
   * @param {object} appData
   */
  saveAppData (appData) {
    try {
      window.sessionStorage.setItem(EM_EVENT.appDataStorageKey, JSON.stringify(appData))
    } catch (e) {
      Logger.warn('Save App Data Error')
    }
  }
  /**
   * This function allows widget window to send app data to post message to IDP
   */
  sendAppData () {
    if (this.guest.domElement) {
      try {
        const data = sessionStorage.getItem(EM_EVENT.appDataStorageKey)
        if (data) {
          try {
            let jsonData = JSON.parse(data)
            delete jsonData.meta
            this.guest.domElement.contentWindow.postMessage({
              type: EM_EVENT.sendWidgetAppDataToIdpEvent,
              app: jsonData
            }, '*')
            window.sessionStorage.removeItem(EM_EVENT.appDataStorageKey)
          } catch (e) {
            Logger.error('Unable to send app data from widget to idp' + e)
          }
        } else {
          Logger.warn('Unable to get app data from session storage')
        }
      } catch (e) {
        Logger.warn('Send App Data Error')
      }
    }
  }

  /**
   * set Widget initialize url session storage
   * @param {object} urlPathParamsObj - {path: '', search: ''}
   * e.g.
   * {
   *  path: '/loan-app',
   *  search: '?siteId=123123&workflowId=1231231'
   * }
   */
  setInitUrlParams (urlPathParamsObj, forceResume = false) {
    try {
      window.sessionStorage.setItem(`${this.wid}${RESUME_STATE_KEY}`, forceResume)
      window.sessionStorage.setItem(`${this.wid}${INIT_STATE_KEY}`, JSON.stringify(urlPathParamsObj))
    } catch (e) {}
  }
  /**
   * resolve Widget URL, if the page is refreshed, this function will get the widget url from session storage
   * forece resume state if the force resume session storage item is present
   * @private
   */
  _resolveWidgetURL () {
    let savedInitState
    try {
      const forceResumeIFrameState = /true/ig.test(window.sessionStorage.getItem(`${this.wid}${RESUME_STATE_KEY}`))
      window.sessionStorage.removeItem(`${this.wid}${RESUME_STATE_KEY}`)
      if (forceResumeIFrameState || window.performance) {
        // only use last state when page reload
        if (forceResumeIFrameState || performance.navigation.type === WINDOW_RELOAD) {
          savedInitState = window.sessionStorage.getItem(`${this.wid}${INIT_STATE_KEY}`)
        }
      }  
    } catch(e){
      console.warn('Unable to resolve Widget URL from Session Storage')
    }

    let path = this.basePath
    let search = ''
    if (savedInitState) {
      try {
        let savedInitStateObj = JSON.parse(savedInitState)
        path = savedInitStateObj.path
        search = savedInitStateObj.search
      } catch (e) {
        Logger.error('Invalid wiget intial state format ' + savedInitState)
      }
    }

    const currentQueryParamsObject = queryStringToObject(window.location.search)
    const queryParamsObject = Object.assign({},
      queryStringToObject(search), // last visited params
      this.params, // widget config param
      currentQueryParamsObject, // top url params
      {
        siteid: this.siteId,
        site_id: this.siteId,
        wid: this.wid
      })

    // console.log('host window query params', currentQueryParamsObject)
    let queryString = objectToQueryString(queryParamsObject)

    this.src = `${this.baseUrl}${path}?${queryString}`
    // console.log('iframe query params', this.src)

    return {
      url: this.baseUrl,
      path: path,
      queryParamsObject: queryParamsObject
    }
  }
  /**
   * This resolve function by name from the widget extends property
   * @param {string} property name
   * @private
   */
  _resolveExtendedProperty (propertyName) {
    return getPropertyByPath(this.extends, propertyName)
  }
  _resolveExtendedFunction (functionName) {
    let fn = getPropertyByPath(this.extends, functionName)
    if (!fn || typeof fn !== 'function') {
      return
    }
    return fn
  }
  /**
   * Create widget iframe with SSF and attach to DOM host
   * Create the guest under SSF host
   * @param {object} ssfHost ssfHost object
   * @param {object} widgetDomHost host element of the widget iframe
   */
  create (ssfHost, widgetDOMHost) {
    const urlObject = this._resolveWidgetURL()
    let viewPort = getViewPortDimension()
    let defaultMinHeight = viewPort.height
    if (viewPort.height < LOWEST_WIDGET_MIN_HEIGHT) {
      defaultMinHeight = LOWEST_WIDGET_MIN_HEIGHT
    }
    // create guest from SSF host
    this.guest = ssfHost.renderGuest(urlObject.queryParamsObject,
      `${urlObject.url}${urlObject.path}`, widgetDOMHost, { allowSameOrigin: true, allowPopupsToEscapeSandbox: true })

    this.parentRef = widgetDOMHost
    // set iframe initial style
    this.guest.domElement.style.width = this.style.width
    this.guest.domElement.style.height = this.style.height
    // use view port height as min height if not defined
    this.guest.domElement.style.minHeight = this.style.minHeight || defaultMinHeight + 'px'
    this.guest.domElement.setAttribute('data-wid', this.wid)
    this.guest.domElement.setAttribute('frameborder', 0)
    if (this.ariaLabel) {
      this.guest.domElement.setAttribute('aria-label', this.ariaLabel)
    }
    let sandboxAttr = this.guest.domElement.getAttribute('sandbox')
    sandboxAttr = `${sandboxAttr} allow-top-navigation allow-top-navigation-by-user-activation`
    this.guest.domElement.setAttribute('sandbox', sandboxAttr)
    // #if !process.env.CUSTOM_IFRAME_RESIZER_ENABLED
    if (typeof iFrameResize === 'function') {
        iFrameResize({ log:false, checkOrigin: false, heightCalculationMethod: 'taggedElement' }, `#${this.guest.id}`) //eslint-disable-line
    }
    // #endif
  }

  /**
   * Set widget iframe attributes, for e.g. data-wid
   * @param {string} name attribute name
   * @param {string} value attribute value
   */
  setAttribute (name, value) {
    if (this.guest.domElement) {
      this.guest.domElement.setAttribute(name, value)
    }
  }

  /**
   * this function allows lender send to post message to the iframe widget
   * @param {string} eventName name of the event that sends into the widget
   * @param {string} message event message that sends into the widget
   */
  send (eventName, message) {
    if (this.guest.domElement && this.wid !== AUTH_WIDGET_INSTANCE_ID) {
      this.guest.domElement.contentWindow.postMessage(JSON.stringify({
        name: eventName,
        message,
      }), this.src)
    }
  }
  /**
   * This set new widget iframe height
   * @param {number} newHeight
   */
  setHeight (newHeight) {
    this.guest.domElement.style.height = newHeight + 'px'
  }
  /**
   * This start timer time SSO auth timer "this._authTimer"
   * It will execute authErrorHandler when timer ends
   * It execute widget extends handler and interceptor when present
   * @param {number} delay
   * @param {string} authErrorUrl
   */
  startAuthTimer (delay, authErrorUrl) {
    let derivedDelayValue = this._resolveExtendedProperty(EXTENDS_PROPERTIES.SSO_AUTH_TIMEOUT) ||
    delay || DEFAULT_AUTH_TIMEOUT

    // default login error handler
    authErrorUrl = authErrorUrl + '&wid=' + this.wid
    let authErrorHandler = () => {
      try {
        let loginErrorInterceptor = this._resolveExtendedFunction(EXTENDS_PROPERTIES.SSO_LOGIN_ERR_INTERCEPTOR)
        if (loginErrorInterceptor) {
          Logger.info('Auth Timeout Error executes login error interceptor ' +
          EXTENDS_PROPERTIES.SSO_LOGIN_ERR_INTERCEPTOR)
          loginErrorInterceptor.call()
        }
      } finally {
        // event if interceptor ends in error always redirect
        Logger.error('Auth Timeout Error: Redirect to ' + authErrorUrl)
        this.setAttribute('src', authErrorUrl)
      }
    }

    // override default auth error handler if exist
    let customAuthErrorHandler = this._resolveExtendedFunction(EXTENDS_PROPERTIES.SSO_LOGIN_ERR_HANDLER)
    if (customAuthErrorHandler) {
      Logger.info('use custom Error Handler ' + EXTENDS_PROPERTIES.SSO_LOGIN_ERR_HANDLER)
      // try to get custom error handler
      authErrorHandler = customAuthErrorHandler
    }

    try {
      /**
       * prevent duplicate timer
       * do not start a new timer if already exist
       **/
      if (this._authTimer) {
        return
      }
      // execute login error handler on timeout
      this._authTimer = setTimeout(() => {
        try {
          authErrorHandler.call()
          Logger.info('Auth Error Handler executed')
        } catch (e) {
          Logger.error('Custom Auth Error Handler error : ' + e)
        }
      }, derivedDelayValue)
      Logger.info('Auth Timer started with timeout: ' + derivedDelayValue)
    } catch (e) {
      Logger.error('Auth Timeout Error: ' + e)
    }
  }
  /**
   * This stops "this._authTimer" within the widget which is started by startAuthTimer function
   */
  stopAuthTimer () {
    window.clearTimeout(this._authTimer)
    /**
     * Clear timeout reference value
     * So that new timeout can be created if needed
     */
    this._authTimer = null
    Logger.info('Auth Timer Stopped')
  }
  /**
   * This returns extendable functions from widget extend property
   * @param {object} extendObject
   */
  _resolveWidgetExtendedProperties (extendObject) {
    let resolvedObject = resolveObjectFromNamespace(extendObject)
    if (!resolvedObject) {
      return
    }
    Logger.info('Widget extension detected')
    Object.keys(EXTENDS_PROPERTIES).forEach((prop) => {
      let resolvedProperties = resolvePropertiesFromObject(resolvedObject,
        EXTENDS_PROPERTIES[prop])
      if (resolvedProperties) {
        Logger.info('Widget extension ' + EXTENDS_PROPERTIES[prop] + ' registered')
        resolvedObject[EXTENDS_PROPERTIES[prop]] = resolvedProperties
      } else {
        Logger.info('Widget extension ' + EXTENDS_PROPERTIES[prop] + ' not implemented, use default')
      }
    })
    return resolvedObject
  }

  /**
   * This function used to fetch parent session storage data from widget
   */
  getStoredData () {
      try {
          const storage = window.sessionStorage
          const sessionData = {}
          Object.keys(storage).forEach((sessionKey) => { 
            const appKey = sessionKey.replace(APP_SESSION_PREFIX, '')
            sessionData[appKey] = storage.getItem(sessionKey) 
          })
          return sessionData
      } catch (error) {
        Logger.debug('getStoredData: could not get data from the host ' + error)
      }
  }
}

export default Widget
