export const LEVEL = {
  DEBUG: 1,
  INFO: 2,
  WARN: 3,
  ERROR: 4
}

const messageFormatter = (message) => {
  let dt = new Date()
  return `MESSAGE: ${message} ,TIME: ${dt}`
}

const shouldPrintLog = (currentLoggerLevel, thisLoggerLevel) => {
  return (thisLoggerLevel >= parseInt(currentLoggerLevel, 10))
}

const findKeyByVal = (Obj, val) => {
  val = parseInt(val, 10)
  for (var k in Obj) {
    if (Obj[k] === val) {
      return k
    }
  }
}

export const Logger = {
  level: LEVEL.DEBUG,
  setLogLevel: (level) => {
    Logger.level = level
    let debugLevel = findKeyByVal(LEVEL, level)
    console.log('EMLauncher logger level is set to', debugLevel,
      'Only show log with', debugLevel, 'level or above will show.',
      'Change log level using e.g. data-log-level="3" in widget attribute.',
      'Available debug levels: DEBUG: 1, INFO: 2, WARN: 3, ERROR: 4')
  },
  debug: (message) => {
    if (shouldPrintLog(Logger.level, LEVEL.DEBUG)) {
      console.debug('EMLauncher DEBUG: ' + messageFormatter(message))
    }
  },
  info: (message) => {
    if (shouldPrintLog(Logger.level, LEVEL.INFO)) {
      console.info('EMLauncher INFO: ' + messageFormatter(message))
    }
  },
  warn: (message) => {
    if (shouldPrintLog(Logger.level, LEVEL.WARN)) {
      console.warn('EMLauncher WARN: ' + messageFormatter(message))
    }
  },
  error: (message) => {
    if (shouldPrintLog(Logger.level, LEVEL.ERROR)) {
      console.error('EMLauncher ERROR: ' + messageFormatter(message))
    }
  }
}
