
import { merge } from 'lodash'

const ENVIRONMENT_CONFIG_OVERWRITE = {
  __ENV_OVERWRITE__: false
}
// local config
const ENV_CONFIG = merge({
  version: '2',
  EM_IDP_REDIRECT_URL: 'https://int.idp.ellielabs.com/ccbp/redirect.html',
  WIDGET_CATALOG: {
    'ecc-loan-app': {
      baseUrl: 'http://localhost:3000',
      basePath: '/loan-app/',
      config: {},
      style: {
        height: '800px',
        width: '100%',
        minHeight: '800px'
      }
    },
    'ecc-borrower-portal': {
      baseUrl: 'http://localhost:3000',
      basePath: '/borrower-app/',
      config: {},
      style: {
        height: '800px',
        width: '100%',
        minHeight: '800px'
      }
    }
  }
}, ENVIRONMENT_CONFIG_OVERWRITE)

export const SSF_HOST_NAME = 'WIDGET_HOST'
export const EM_DECLARATIVE_WIDGET_CLASSNAME = 'em-widget'
export const EM_DECLARATIVE_WIDGET_STATE_ATTRIBUTE_NAME = 'data-em-widget-state'
export const MUTATION_OBSERVER_DEBOUNCE_MS = 250
export const AUTH_WIDGET_INSTANCE_ID = '_AUTH_WIDGET_'
export const UNKNOWN_WIDGET_INSTANCE_ID = '_UNKNOWN_WIDGET_'
export const APP_SESSION_PREFIX = 'ECC_GUEST_'
export const WIDGET_CATALOG = ENV_CONFIG.WIDGET_CATALOG
export const EM_IDP_REDIRECT_URL = ENV_CONFIG.EM_IDP_REDIRECT_URL
export const SCROLL_HANDLER_DEBOUNCE_DURATION = 50 // time in ms
export const HEIGHT_CHECK_INTERVAL = 500 // time in ms
export const WIDGET_PING_INTERVAL = 2000 // ms
export const WIDGET_MIN_HEIGHT_BUFFER = 20 // 20 px lower then real view port height
export const LOWEST_WIDGET_MIN_HEIGHT = 500 // lowest min height
export const DEFAULT_AUTH_TIMEOUT = 600000 // ms (60s)
export const EM_EVENT = {
  tokenReady: 'TOKEN_READY',
  widgetConnected: 'WIDGET_CONNECTED',
  widgetStateChanged: 'WIDGET_STATE_CHANGED',
  widgetReady: 'WIDGET_READY',
  widget2Launcher: 'WIDGET2LAUNCHER',
  launcher2Widget: 'LAUNCHER2WIDGET',
  widgetResized: 'WIDGET_RESIZED',
  widgetIFramePropertiesUpdate: 'IFRAME_PROPERTIES_UPDATE',
  widgetAuthTimerStart: 'START_AUTH_TIMER',
  widgetAuthTimerEnd: 'END_AUTH_TIMER',
  widgetSaveData: 'SAVE_APP_DATA',
  appDataRequestFromIdp2Widget: 'APP_REQUEST',
  appDataStorageKey: '_EM_APP_DATA_',
  sendWidgetAppDataToIdpEvent: 'APP_DATA_INCOMING',
  sendStoredDataToWidget : 'SEND_STORED_DATA_TO_WIDGET'
}
export const WINDOW_RELOAD = 1
export const WIDGET_STATE = {
  INITIAL: 0,
  CONNECTED: 1,
  READY: 2
}
export const EXPOSED_WINDOW_PROPERTIES = [
  'innerWidth',
  'innerHeight',
  'pageXOffset',
  'pageYOffset',
  'location',
  'top',
  'performance',
  'visualViewport'
]

export const EXTENDS_PROPERTIES = {
  SSO_LOGIN_ERR_INTERCEPTOR: 'ssoLoginErrorInterceptor',
  SSO_LOGIN_ERR_HANDLER: 'ssoLoginErrorHandler',
  SSO_AUTH_TIMEOUT: 'ssoAuthTimeout'
}

export const META_PROPERTIES = ['loginWindow', 'externalLoginWindow']

export const EXPOSED_WINDOW_EVENTS = {
  SCROLL: 'scroll',
  ON_BODY_RESIZE: 'onBodyResize',
  ON_WINDOW_RESIZE: 'onWindowResize',
  POP_STATE: 'popstate',
  ON_VISUAL_VIEWPORT_RESIZE: 'onVisualViewportResize'
}
